@font-face {
  font-family: ethnocentric;
  font-style: normal;
  font-weight: normal;
  src: local(ethnocentric), url("ethnocentric.51e59752.woff") format("woff");
}

:root {
  --white-color: #fff;
  --primary-color: #585b60;
  --secondary-color: #fd5634d1;
  --section-bg-color: #f9f9f9;
  --dark-color: #585b60;
  --p-color: #717275;
  --border-color: #e9eaeb;
  --featured-border-color: #727aab;
  --body-font-family: "DM Sans", sans-serif;
  --h1-font-size: 62px;
  --h2-font-size: 48px;
  --h3-font-size: 36px;
  --h4-font-size: 32px;
  --h5-font-size: 24px;
  --h6-font-size: 22px;
  --p-font-size: 18px;
  --menu-font-size: 12px;
  --copyright-font-size: 14px;
  --border-radius-large: 100px;
  --border-radius-medium: 20px;
  --border-radius-small: 10px;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}

body {
  background: var(--white-color);
  font-family: var(--body-font-family);
  color: var(--primary-color);
}

.logo {
  font-family: ethnocentric;
}

.highlight {
  color: var(--white-color);
  position: relative;
}

.highlight:before {
  content: "";
  background-color: var(--secondary-color);
  z-index: -1;
  filter: url("#marker-shape");
  opacity: .7;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  padding: 0 4px;
  position: absolute;
  left: -4px;
}

h2, h3, h4, h5, h6 {
  color: var(--dark-color);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-weight-bold);
  letter-spacing: -1px;
}

h1 {
  font-size: var(--h1-font-size);
  letter-spacing: -3px;
}

h2 {
  font-size: var(--h2-font-size);
  color: var(--secondary-color);
  letter-spacing: -3px;
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
  line-height: normal;
}

h6 {
  font-size: var(--h6-font-size);
}

p, ul li {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-normal);
}

a, button {
  touch-action: manipulation;
  transition: all .3s;
}

a {
  color: var(--p-color);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
}

::selection {
  background: var(--secondary-color);
  color: var(--white-color);
}

::selection {
  background: var(--secondary-color);
  color: var(--white-color);
}

.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.flex-2 {
  flex: 2 !important;
}

.flex-1 {
  flex: 1 !important;
}

b, strong {
  font-weight: var(--font-weight-bold);
}

.section-title-wrap {
  background: var(--secondary-color);
  border-radius: var(--border-radius-small);
  padding: 10px 30px;
}

.avatar-image {
  border-radius: var(--border-radius-large);
  object-fit: cover;
  width: 160px;
  height: 160px;
}

.avatar-image-large {
  width: 90.4px;
  height: 90.4px;
}

.preloader {
  z-index: 99999;
  background: none repeat scroll 0 0 var(--white-color);
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.spinner {
  border-radius: var(--border-radius-small);
  border: 1px solid #0000;
  position: relative;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  border-top-color: var(--white-color);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-top: -10px;
  margin-left: -10px;
  animation: .9s linear infinite spinner;
  position: absolute;
  top: 50%;
  left: 50%;
}

@-webkit- @keyframes spinner {
  to { transform: rotate(360deg) ; }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.navbar-icon {
  background: var(--white-color);
  border-radius: var(--border-radius-large);
  color: var(--dark-color);
  text-align: center;
  width: 47px;
  height: 47px;
  line-height: 47px;
}

.is-sticky .navbar-icon {
  background: var(--secondary-color);
  color: var(--white-color);
}

.form-check-icon {
  color: var(--secondary-color);
}

.custom-btn, .navbar .custom-btn {
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-bold);
}

.navbar .custom-btn {
  border-style: solid;
  border-width: 2px;
  border-color: var(--white-color);
  color: var(--white-color);
  background: none;
  padding: 8px 22px;
}

.navbar .custom-btn:hover {
  background: var(--white-color);
  color: var(--secondary-color);
  border-color: #0000;
}

.custom-btn {
  background: var(--secondary-color);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-weight: var(--font-weight-bold);
  padding: 12px 24px;
}

.custom-btn:hover {
  background: var(--primary-color);
  color: var(--white-color);
  box-shadow: 0 1rem 3rem #0000002d;
}

.custom-border-btn {
  border: 2px solid var(--border-color);
  color: var(--p-color);
  background: none;
}

.custom-border-btn:hover {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--white-color);
}

.custom-link {
  background-color: var(--primary-color);
}

.custom-link:hover {
  background-color: var(--secondary-color);
}

.sticky-wrapper {
  z-index: 222;
  position: relative;
  height: auto !important;
}

.is-sticky, .is-sticky .navbar .container {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem #0000002e;
}

.is-sticky .navbar-brand, .is-sticky .navbar-brand:hover {
  color: var(--primary-color);
}

.is-sticky .navbar-brand-accent, .is-sticky .navbar-brand-accent:hover {
  color: var(--secondary-color);
}

.is-sticky .navbar-nav .nav-link {
  color: var(--p-color);
}

.is-sticky .navbar .custom-btn {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.is-sticky .navbar .custom-btn:hover {
  background: var(--secondary-color);
  color: var(--white-color);
}

.navbar {
  z-index: 9;
  background: none;
  padding-top: 15px;
  padding-bottom: 0;
  transition: all .3s;
  position: absolute;
  left: 0;
  right: 0;
}

.navbar .container {
  border-radius: var(--border-radius-small);
  padding: 10px 25px;
}

.navbar-brand {
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-bold);
  flex: 1;
  font-family: ethnocentric;
  position: relative;
}

.navbar-brand-accent {
  transform-origin: bottom;
  margin: 0;
  font-size: 7px;
  position: absolute;
  transform: rotate(55deg)translate(-5px, 20px);
  color: var(--secondary-color) !important;
}

.navbar-brand, .navbar-brand:hover {
  color: var(--white-color);
}

.navbar-expand-lg .navbar-nav .nav-link {
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .nav-link {
  color: var(--section-bg-color);
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
  display: inline-block;
  position: relative;
}

.navbar-nav .nav-link:after {
  content: "";
  background: none;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
}

.navbar-nav .nav-link.active:after, .navbar-nav .nav-link:hover:after {
  background: var(--secondary-color);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link:hover {
  color: var(--secondary-color);
}

.navbar-toggler {
  cursor: pointer;
  border: 0;
  outline: none;
  width: 30px;
  height: 35px;
  margin: 0;
  padding: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before, .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transition: top .3s 50ms, transform .3s .35s, -webkit-transform .3s .35s;
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
}

.navbar-toggler .navbar-toggler-icon {
  background: var(--white-color);
  width: 30px;
  height: 2px;
  transition: background 10ms .3s;
  display: block;
  position: relative;
}

.navbar-toggler .navbar-toggler-icon:before, .navbar-toggler .navbar-toggler-icon:after {
  background: var(--white-color);
  content: "";
  width: 30px;
  height: 2px;
  transition: top .3s .35s, transform .3s 50ms, -webkit-transform .3s 50ms;
  position: absolute;
  left: 0;
  right: 0;
}

.navbar-toggler .navbar-toggler-icon:before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon:after {
  top: 8px;
}

.hero {
  background: var(--secondary-color);
  padding-top: 330px;
  padding-bottom: 330px;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 991px) {
  .hero {
    height: 60vh;
  }
}

.hero-title, .hero h2 {
  background: var(--white-color);
  border-radius: var(--border-radius-large);
  padding: 8px 24px;
  display: inline-block;
  box-shadow: 0 1rem 3rem #0000002d;
}

.hero-title {
  font-size: 44px;
}

.hero h2 {
  font-size: 38px;
}

.hero-text {
  z-index: 22;
  position: relative;
  top: 70px;
}

.hero-image-wrap {
  background: var(--white-color);
  z-index: 22;
  pointer-events: none;
  border-radius: 100%;
  width: 350px;
  height: 350px;
  margin: auto;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
}

.hero-image {
  z-index: 22;
  width: 100%;
  min-width: 650px;
  position: absolute;
  top: 0;
  transform: translateX(-90px);
}

.hero svg {
  z-index: 2;
  pointer-events: none;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.profile-thumb {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
}

.profile-title {
  border-bottom: 1px solid var(--border-color);
  padding: 15px 30px;
}

.profile-small-title {
  border-right: 1px solid var(--border-color);
  color: var(--secondary-color);
  font-weight: var(--font-weight-bold);
  min-width: 140px;
  margin-right: 10px;
  padding: 13px 30px;
  display: inline-block;
}

.profile-body p {
  margin-bottom: 0;
}

.profile-body p:nth-of-type(2n) {
  background: var(--white-color);
}

.about-image {
  border-radius: var(--border-radius-medium);
  object-fit: cover;
  height: 100%;
}

.about-thumb {
  padding-left: 20px;
  padding-right: 20px;
}

.featured-numbers {
  font-size: var(--h1-font-size);
  line-height: normal;
  display: block;
}

.featured-text {
  color: var(--secondary-color);
}

.featured-border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.featured-border-start {
  border-left: 1px solid var(--border-color);
}

.clients {
  background: var(--section-bg-color);
}

.clients-item-height {
  height: 120px;
}

.clients-image {
  transition: all .2s;
  display: block;
}

.clients-image:hover {
  transform: scale(1.3);
}

.services, .featured {
  background: var(--section-bg-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.services-thumb {
  background: var(--white-color);
  border-radius: var(--border-radius-medium);
  border: 2px solid #0000;
  margin-bottom: 24px;
  padding: 40px 40px 240px;
  transition: all .5s;
  position: relative;
  overflow: hidden;
}

.services-thumb-up {
  margin-bottom: -50px;
  position: relative;
  bottom: 50px;
}

.services-thumb:hover {
  border: 2px solid var(--secondary-color);
  box-shadow: 0 1rem 3rem #0000002d;
}

.services-thumb:hover .services-icon-wrap {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--white-color);
}

.services-icon-wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-small);
  width: 50%;
  height: 55%;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(-35deg)translateY(55px);
}

.services-icon {
  font-size: 90px;
  position: relative;
  bottom: 15px;
  transform: rotate(35deg);
}

.services-thumb:hover .services-price-wrap {
  background: var(--secondary-color);
}

.services-thumb:hover .services-price-overlay {
  background: var(--primary-color);
}

.services-price-wrap {
  background: var(--primary-color);
  border-radius: var(--border-radius-medium);
  padding: 6px 20px 6px 15px;
  transition: all .5s;
  position: relative;
  overflow: hidden;
}

.services-price-text {
  color: var(--white-color);
  font-size: var(--copyright-font-size);
  font-weight: var(--font-weight-bold);
}

.services-price-overlay {
  background: var(--secondary-color);
  pointer-events: none;
  border-bottom-left-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.projects-thumb {
  background: var(--section-bg-color);
  border: 2px solid var(--white-color);
  border-radius: var(--border-radius-medium);
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 40px;
  transition: all .5s;
  position: relative;
  overflow: hidden;
}

.projects-thumb:hover {
  border-color: var(--secondary-color);
}

.projects-thumb:hover .projects-image, .projects-thumb:focus .projects-image {
  transform: rotate(0)translateY(0);
}

.projects-thumb .popup-image {
  width: 100%;
  height: 100%;
  display: block;
}

.projects-image {
  border-radius: var(--border-radius-medium);
  width: 100%;
  transition: all .5s;
  display: block;
  transform: rotate(10deg)translateY(80px);
}

.projects-title {
  margin-bottom: 20px;
}

.projects-tag {
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  color: var(--secondary-color);
  text-transform: uppercase;
  margin-bottom: 5px;
}

.contact-info {
  background: var(--white-color);
  border-top-right-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
  height: 100%;
  padding: 60px 30px 30px;
}

.contact-info-border-start {
  border-right: 1px solid var(--border-color);
  border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
}

.contact-form {
  margin-left: 10px;
}

.custom-form .form-control {
  background: var(--white-color);
  box-shadow: none;
  border: 2px solid var(--border-color);
  color: var(--p-color);
  outline: none;
  margin-bottom: 24px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.form-floating > label {
  color: var(--p-color);
}

.form-check-inline {
  vertical-align: middle;
  width: 100%;
  margin-top: 0;
  margin-bottom: 24px;
  margin-right: 0;
  padding: 0;
  position: relative;
}

.custom-form .form-check-label {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-check-label-text {
  color: var(--p-color);
  font-size: copyright-font-size;
  margin-top: 5px;
  display: block;
}

.form-check-input[type="checkbox"] {
  background: var(--white-color);
  border: 2px solid var(--border-color);
  box-shadow: none;
  outline: none;
  width: 100%;
  margin-top: 0;
  margin-left: 0;
  padding: 40px 50px;
}

.form-check-input:checked[type="checkbox"] {
  background-image: none;
}

.form-check-input:hover, .form-check-input:checked {
  border-color: var(--secondary-color);
  background-color: #0000;
}

.custom-form .form-control:hover, .custom-form .form-control:focus {
  border-color: var(--secondary-color);
  background: none;
}

.custom-form .form-floating textarea {
  height: 150px;
}

.custom-form button[type="submit"] {
  background: var(--secondary-color);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-weight: var(--font-weight-bold);
  border: none;
  margin-bottom: 0;
  transition: all .3s;
}

.custom-form button[type="submit"]:hover, .custom-form button[type="submit"]:focus {
  background: var(--primary-color);
  border-color: #0000;
}

.site-footer {
  border-top: 1px solid var(--border-color);
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.site-footer-title {
  font-size: var(--menu-font-size);
  color: var(--dark-color);
  text-transform: uppercase;
}

.copyright-text-wrap p, .copyright-text {
  font-size: var(--copyright-font-size);
}

.copyright-text {
  border-right: 1px solid var(--border-color);
  margin-right: 20px;
  padding-right: 25px;
}

.copyright-text-wrap a {
  font-weight: var(--font-weight-bold);
}

.footer-menu {
  margin: 0;
  padding: 0;
}

.footer-menu-item {
  vertical-align: top;
  list-style: none;
  display: inline-block;
}

.footer-menu-link {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
  font-size: var(--copyright-font-size);
  font-weight: var(--font-weight-medium);
  vertical-align: top;
  text-align: center;
  min-width: 70px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 6px 14px;
  display: inline-block;
}

.footer-menu-link:hover {
  background: var(--secondary-color);
  color: var(--white-color);
  border-color: #0000;
}

.social-icon {
  margin: 0;
  padding: 0;
}

.social-icon-item {
  vertical-align: top;
  list-style: none;
  display: inline-block;
}

.social-icon-link {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-large);
  font-size: var(--copyright-font-size);
  color: var(--dark-color);
  vertical-align: top;
  text-align: center;
  width: 40px;
  height: 40px;
  margin: 2px 2px 5px;
  line-height: 40px;
  display: inline-block;
}

.social-icon-link:hover {
  background: var(--secondary-color);
  color: var(--white-color);
  border-color: #0000;
}

@media screen and (min-width: 1600px) {
  .hero {
    padding-top: 380px;
    padding-bottom: 380px;
  }

  .hero-image-wrap {
    width: 400px;
    height: 400px;
    top: -50px;
  }

  .hero-image {
    min-width: 650px;
  }

  .hero-title, .hero h2 {
    font-size: var(--h2-font-size);
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .custom-btn, .navbar .custom-btn {
    font-size: var(--copyright-text-font-size);
    padding: 8px 16px;
  }

  .navbar .container {
    background: var(--white-color);
  }

  .navbar-brand, .navbar-brand:hover {
    color: var(--primary-color);
  }

  .navbar-icon {
    background: var(--secondary-color);
    color: var(--white-color);
    width: 44px;
    height: 44px;
    line-height: 44px;
  }

  .navbar .custom-btn {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
  }

  .navbar-toggler .navbar-toggler-icon, .navbar-toggler .navbar-toggler-icon:before, .navbar-toggler .navbar-toggler-icon:after {
    background: var(--dark-color);
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 0;
  }

  .navbar-nav .nav-link {
    color: var(--p-color);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .hero {
    padding-top: 200px;
    padding-bottom: 400px;
  }

  .hero-text {
    margin-bottom: 120px;
    top: 0;
  }

  .about-thumb {
    padding-left: 0;
    padding-right: 0;
  }

  .about-numbers {
    font-size: 42px;
  }

  .services-thumb-up {
    margin-bottom: 32px;
    bottom: 0;
  }

  .services-thumb {
    margin-bottom: 32px;
    padding-bottom: 270px;
  }

  .services-icon-wrap {
    width: 45%;
    height: 60%;
  }

  .services .col-lg-10 .row .col-lg-6:last-child, .projects .col-lg-4:last-child {
    margin-bottom: 0;
  }

  .projects-thumb {
    margin-top: 0;
    margin-bottom: 32px;
  }

  .contact-info {
    border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
    padding: 40px 30px;
  }

  .contact-info-border-start {
    border-right: 0;
    border-bottom: 1px solid var(--border-color);
    border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
  }
}

@media screen and (max-width: 575px) {
  .navbar .container {
    margin-left: 12px;
    margin-right: 12px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  .custom-btn, .navbar .custom-btn {
    padding: 6px 12px;
    font-size: 13px;
  }

  .navbar-icon {
    font-size: var(--copyright-font-size);
    width: 35.5px;
    height: 35.5px;
    line-height: 35.5px;
  }

  .hero-image-wrap {
    width: 300px;
    height: 300px;
  }

  .hero-image {
    min-width: inherit;
  }
}

/*# sourceMappingURL=index.c3aa54bd.css.map */
